import React from 'react';
import { Link } from 'react-router-dom';
import './Cards.css';
import { cards } from './cardsData';

function Cards() {
  const renderCards = () => {
    return cards.map((card, index) => {
      // Toggle imageOnLeft based on even/odd index
      const imageOnLeft = index % 2 === 0;

      return (
        <section className="card" key={index}>
          <div className={`card-content ${imageOnLeft ? 'image-on-left' : 'image-on-right'}`}>
            <div className="picture-container">
              <img src={card.image} alt={`Card ${index}`} />
            </div>

            <div className="writeup">
              <Link to={card.link} className="title-text">
                {card.title}
              </Link>
              <p>{card.content}</p>
            </div>
          </div>
        </section>
      );
    });
  };

  return <div className="card-container">{renderCards()}</div>;
}

export default Cards;





