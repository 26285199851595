import React from 'react';
import ResearchCard from './ResearchCards';
import './ResearchCardGrid.css';

function ResearchCardGrid({ cards }) {
  return (
    <div className="card-grid">
      {cards.map((card, index) => (
        <ResearchCard
          key={index}
          title={card.title}
          pictureSrc={card.pictureSrc}
          content={card.shortContent}
          buttonText={card.buttonText}
          linkTo={card.linkTo}
          targetSection={card.targetSection}
          scrollId = {card.targetSection}
        />
      ))}
    </div>
  );
}

export default ResearchCardGrid;