// In src/components/pages/GeoHealth.js
import styles from './GeoHealth.module.css';

import React from 'react';

import CourseModules from './CourseModules'; 


const GeoHealth = () => {

  return (
    
    <div className={styles.geoMain}>
      <div className={styles.headerNavContainer}>
        <div className={styles.geoHeader}>
      <h1 className={styles.header1}>GeoHealth</h1>
      <h1 className={styles.header2}>(Introduction to GeoHealth & Climate)</h1>
      <h1 className={styles.header3}>Spring 2024</h1>
      <p>Tue/Thu 9:30 am - 11:00 am (GEO-391/71T 27200)</p>
      <p>ECJ (Cockrell School) 3.110</p>
      <p className={styles.email}>Instructor: Dev Niyogi (dev.niyogi@jsg.utexas.edu)</p>
      <p>TA: Trevor Brooks (tbrooks4343@utexas.edu)</p>
      </div>

      <div className={styles.navMenu}>
        <a href="#introduction">Introduction</a>
        <a href="#aboutProfessor">About Instructor</a>
        <a href="#courseStructure">Course Structure</a>
        <a href="#aboutTA">About the TA</a>

        <a href="#courseModules">Course Modules</a> 
        <a href="/images/syllabus.pdf" target="_blank" download>Download Syllabus PDF</a>
      </div>

      </div>

      <div className={styles.section} id="introduction">
        <h2>Introduction</h2>
        <p>Human health is intertwined with the health of our surrounding environment. As our climate changes or our environment deteriorates, traditional health trends may become altered. From the increased range of infectious diseases and pandemics, or the threats of vector borne diseases to an increase in heat-related mortality, to increases in cancer due to chemical exposure, there is a close relationship between the environment and the health of individuals around the world. This course aims to examine the health impacts that result from climate change and environmental degradation and how the health world has aimed to respond to these changes. The course will examine both historical and contemporary health issues related to environmental changes or degradation.</p>
        <p>This course, originally developed by Dr. Dev Niyogi in the Jackson School of Geosciences, is interdisciplinary as it not only looks at the “hard science” that results in these changing health outcomes but also examines these issues through lenses of policy, economics, social science, and engineering.</p>
        <p>This course will arm the student with the ability to critically analyze complex climate, health, and wellbeing and conduct that analysis with data so that they can become literate in the geohealth discipline. It will not only allow students to examine and analyze climate data for application to health studies, but also communicate the intricacies of contemporary and historical literature.</p>
      </div>

      <div id="aboutProfessor" className={styles.section}>
        <h2>About the Professor</h2>
         <div className={styles.professorContent}>
            <img src="/images/dev3.jpg" alt="Professor" className={styles.professorImage}/>
            <div className={styles.professorText}>
      
            <p>Professor and William Stamps Farish Chair,
Department of Earth and Planetary Sciences,
Jackson School of Geosciences, and Department
of Civil, Architectural and Environmental
Engineering, Cockrell School of Engineering,
Affiliate Faculty: Oden Institute for
Computational Engineering and Sciences, and UT
Center for Space Research, The University of
Texas at Austin</p>
            </div>
         </div>
    </div>
    <div id="aboutTA" className={styles.section}>
        <h2>About the TA</h2>
        <div className={styles.professorContent}>
            <img src="images/trevor.jpg" alt="Trevor Brooks" className={styles.professorImage}/>
            <div className={styles.professorText}>
                <p>Hello! My name is Trevor Brooks. I am from Annapolis, MD, but I attended
Bridgewater College (in Virginia) for my
undergraduate in Environmental and Political
Sciences. I am currently pursuing a PhD in the
Jackson School of Geosciences studying the urban
heat island effect and sustainable infrastructure. I love
to workout, hike, and explore. I look forward to being
your TA this semester and getting you more excited
about Geohealth! Please email me if you have any
questions. Please call me Trevor! Email:
Tbrooks4343@utexas.edu</p>
                </div>
            </div>
        </div>
      <div id="courseStructure" className={styles.section}>
        <h2>Course Structure</h2>
        <p>This course meets two times a week on Tuesdays and Thursdays from 9:30-11:00 AM in ECJ (Cockrell School) 3.110. The classes will be structured differently with one of the classes being more lecture oriented and the other being more oriented towards class discussions. Students are expected to research materials outside of class to be prepared for these discussions.</p>
        <p>The course has seven modules with each module being about two weeks long. A tentative breakdown of the course can be seen in the table here. This schedule is only tentative and is for informational purposes only.</p>
         <h2>Assignments</h2>
        <h3>1. Student Led Discussions</h3>
        <p>You and a partner (depending on numbers), working with the TA will be responsible for leading a class in a module of your choosing. It will be the responsibility of the students to become familiar with the module beforehand and lead a discussion or activity/ies about the module subject. This could be a case study, simulation, or simple Socratic seminar.</p>
        
        {/* Subheading 2: Term Paper */}
        <h3>2. Term Paper</h3>
        <p>You will be asked to write a term paper about a climate and health issue that has been covered in the course already. Your paper should touch on how your climate data shows how risk is projected to change or can be managed in the future. The potential policies or engineering/science solutions that may help mitigate or allow for adaptations. Students are encouraged to ground their work in historical case studies that will provide context to the contemporary issue. You will be asked to present your work to your peers.</p>
        
        {/* Subheading 3: Homework */}
        <h3>3. Homework</h3>
        <p>Each Module will have a homework assignment attached to it that will be due before the last class within the module. These homeworks may ask students to use calculations, or they may be more open-ended responses that may require some outside research.</p>

      </div>

      
      
      <div id="courseModules"> {/* Add this id */}
        <CourseModules />
      </div>

      
    </div>
    
    
  );
  




};

export default GeoHealth;


