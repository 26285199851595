import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import VideoCarousel from './VideoCarousel';
import VideoSlider from './VideoSlider';
const videos = [
  { src: process.env.PUBLIC_URL + '/videos/video1.mp4', title: 'TE(x)US L∆B', subtitle: "Welcome to the" },
  { src: process.env.PUBLIC_URL + '/videos/title_video-2.mov', thirdtext: ' We investigate the roles and interactions between extreme weather and climate with urban environments.' },
  { src: process.env.PUBLIC_URL + '/videos/title_video-3.mov', thirdtext: ' Our goal is to be at the forefront of urban climate and weather research and to make our science useful to useable' },
  { src: process.env.PUBLIC_URL + '/videos/Austin-2.mp4', thirdtext: 'We are a part of the Jackson School of Geosciences and the Cockrell School of Engineering at The University of Texas at Austin',logo: process.env.PUBLIC_URL + '/images/knockout_university_primary.png'  },
  // Add more video sources as needed
];

function HeroSection() {
  return (
    <div className="hero-container">
      
      <div className="hero-slider">
        <VideoSlider  videos={videos} />
      </div>
    </div>
  );
}


export default HeroSection;
