// Publications.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./Publications.css"

const Publications = () => {
  const [publications, setPublications] = useState([]);

  useEffect(() => {
    // Fetch the data from a text file
    fetch('/publications.txt')
      .then((response) => response.text())
      .then((text) => {
        // Split the text into publications by blank lines
        const publicationLines = text.split('\n\n');
        const formattedPublications = [];

        // Process the lines to extract names and links
        for (let i = 0; i < publicationLines.length; i++) {
          const publicationName = publicationLines[i];
          const publicationLink = publicationLines[i + 1];
          formattedPublications.push({ name: publicationName, link: publicationLink });
          i++; // Skip the next line, which is the link
        }

        setPublications(formattedPublications);
      })
      .catch((error) => {
        console.error('Error fetching publications:', error);
      });
  }, []);

  return (
    <div className="publications-container">
      <h1>Publications</h1>
      <ul className="publications-list">
        {publications.map((publication, index) => (
          <li key={index} className="publication-item">
            <a href={publication.link} className="pub-name" target="_blank" rel="noopener noreferrer">
              {publication.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Publications;