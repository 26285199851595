// teamData.js

export const principalInvestigators = [
  {
    name: "Dev Niyogi",
    title: "Professor and William Stamps Farish Chair \n Department of Earth and Planetary Sciences, \n Jackson School of Geosciences and  \nMaseeh Department of Civil, Architectural,\n and Environmental Engineering \n Affiliate Faculty: Oden Institute of Computational Engineering and Sciences, UT Center for \nSpace Research, and Energy, and \nEarth Resources (EER) \n Co-Director UT-City Climate CoLab ",
    image: process.env.PUBLIC_URL + "/images/Dev-Pic-Aug2024.jpg",
  },
  // Add more PI members here
];

export const postdoctoralResearchers = [
  {
    name: "Ali Mousavinezhad ",
    image: process.env.PUBLIC_URL + "/images/Ali_Mousavinezhad_1.png",
  },
   {
    name: "Alka Tiwari",
    image: process.env.PUBLIC_URL + '/images/Alka.png',
  },
  {
    name: "Koorosh Azizi",
    image: process.env.PUBLIC_URL + "/images/Koorosh_2.png",
  },
  {
    name: "Manmeet Singh",
    image: process.env.PUBLIC_URL + "/images/manmeet.png",
  },
  {
    name: "Naveen Sudharshan",
    image:process.env.PUBLIC_URL +"/images/naveen.png",
  },
  {
    name: "Shyama Mohanty",
    image: process.env.PUBLIC_URL + "/images/Shyama_1.png",
  },
  

  // Add more postdoc members here
];


export const Staff
 = [
  {
    name: "Allysa Dallmann",
    image:process.env.PUBLIC_URL +"/images/Allysa.png",
  },
  {
    name: "Alexia Leclercq",
    image: process.env.PUBLIC_URL + "/images/alexia.png",
  },
  {
    name: "Dariya Fallon",
    image: process.env.PUBLIC_URL + "/images/defaultpic.png",
  },
  

  // Add more postdoc members here
];

export const Scientists
 = [
  {
    name: "Hassan Dashtian",
    image:process.env.PUBLIC_URL +"/images/Hassan.jpeg",
  },
  

  // Add more postdoc members here
];

export const graduateStudents = [
  {
    name: "Aditya Patel",
    image: process.env.PUBLIC_URL + "/images/Adi.png",
  },
  {
    name: "Amanda Pfiel",
    image: process.env.PUBLIC_URL + '/images/Amanda_2.png',
  },
  {
    name: "Arya Chavoshi",
    image: process.env.PUBLIC_URL + '/images/Arya.png',
  },
  {
    name: "Caleb Adams",
    image: process.env.PUBLIC_URL + '/images/Caleb.png',
  },
  {
    name: "Harsh Kamath",
    image: process.env.PUBLIC_URL + '/images/Harsh.png',
  },
  {
    name: "Sasanka Talukdar",
    image: process.env.PUBLIC_URL + '/images/Sasanka.png',
  },
  {
    name: "Ting-Yu Dai",
    image: process.env.PUBLIC_URL + '/images/Ting-Yu.png',
  },
  {
    name: "Trevor Brooks",
    image: process.env.PUBLIC_URL + '/images/Trevor.png',
  },  
  {
    name: "Xinxin Sui",
    image: process.env.PUBLIC_URL + '/images/Sui_2.png',
  },

  // Add more graduate student members here
];


export const Undergrads = [

  {
    name: "Anush Bansal"
  },
  {
    name: "Hiruni Dissanayake"
  },
  {
    name: "Sarah Steele"
  },

  // Add more postdoc members here
];

export const Visitors
 = [
  {
    name: "Alka Tiwari, Purdue University (Summer 2024)"
  },
  {
    name: "Prof. Krishna Osuri, NIT Rourkela, India (Summer 2024)"
  },
  {
    name: "Oliver Visiting"
  }
   
  // Add more visitors members here
];

export const VisitingSpeakers
 = [
  {
    name: "Dr. Ashok Dimri, Director IIG and Professor JNU (Summer 2024)"
  },
  {
    name: "Dr. R. Krishnan, Director Indian Institute of Tropical Meteorology (May 2024)"
  },
  {
    name: "Prof. Jagadish Shukla, George Mason University (April 2024)"
  }
   
  // Add more visitors members here
];

export const PastGrad
 = [
  {
    name: "Esha Krishnan"  
  },
    {
    name: "Irfana Mohamed Asif"
  },
  {
    name: "Matthew Nattier",
  },
  {
    name: "Megan Luu"
  },

  {
    name: "Samuel Fung",
  }
 

  // Add more past grad students members here
];

