import React from 'react';
import { Link } from 'react-router-dom';
import './Introduction.css';
import { Button } from './Button';
import NewsSection from './NewsSection';


function Introduction(){
    return(

        <div className="intro-container">
      <div className="sections-container">
        <section className="introduction">
          <div className="intro-text">
            <h4>Introduction</h4>
            <p>
              We investigate the roles and interactions between extreme weather and climate with urban environments. Our laboratory is particularly interested in understanding these interactions and how to prepare for a changing climate. Using models from the global to local scale we identify signatures in temperature and precipitation patterns to gather a larger understanding of urban climate and its interactions with weather extremes. 

                        Our mission as a lab is to learn more about extreme weather and climate so we can be a part of the mission to assist with climate mitigation and adaptation. We have collaborative efforts with other labs and the City of Austin, so that we are at the forefront of bridging the gap between policy making, community engagement, and climate science.
      
            </p>
          </div>
          <div className="button-container">
            <Button buttonStyle="btn--primary" buttonSize="btn--largest" to="/projects/OngoingProjects">
              Learn More
            </Button>
          </div>
        </section>
        <section className="news">
          <h5>News</h5>
          <div className = "newscards">
          <NewsSection/>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Introduction;