import React from 'react';
import './ContentPage.css'; // Import the CSS file
import { Link } from 'react-router-dom'; 


function ContentPage({ project }) {
  if (!project) {
    return <div>Project not found.</div>;
  }

  const collaborators = project.Collaborators.split(',').map((name) => name.trim());



  return (
    <div className="content-page">
        <img src= {process.env.PUBLIC_URL + "/images/" +  project.pictureSrc} alt={project.title} />
        <div className="content-text">
             <h2>{project.title}</h2>
             <p className = "Description">{project.longContent}</p>
             
             <div className="collaborators">
                
          {collaborators.map((collaborator, index) => {
            // Split the collaborator's name by whitespace
            const [firstName, lastName] = collaborator.split(' ');

            // Create the link using firstName and lastName
            const linkTo = `/People/${firstName}_${lastName}`;

            return (
              <Link key={index} to={linkTo}>
                {collaborator}
              </Link>
            );
          })}
        </div>
        </div>
        
      
      
    </div>
  );
}

export default ContentPage;