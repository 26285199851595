import React from 'react';
import './Hurricane.css'; // Create a new CSS file for hurricane styling.

function HurricaneDebby() {
  return (
    <div className="main">
      <div className="header1">
        <img src="images/Debbybackground.jpeg" alt="Background" className="header-background" />
        <div className="title-box">
          <h1>Hurricane Debby Forecast<br/>University of Texas at Austin, Jackson School of Geosciences/TExUS Lab</h1>
        </div>
        <div className="content-box">
          <p><span style={{ color: 'orange' }}>Simulation output for:</span> Aug 3 to Aug 9, 2024, 00 UTC.</p>
          <p><span style={{ color: 'orange' }}>Current (5 pm, Jul 7) Analysis:</span>Now both physics/dynamical model and the AI/ML model put the track east of Austin going over Houston wheering eastwards.</p>
          <p><span style={{ color: 'orange' }}>Previous (2 pm, Jul 6) Analysis:</span> AI/ML model puts the system east of Austin with modest rainfall (1.5 inches); while the dynamical model still has the system over or west of Austin with rainfall about 5 inches.</p>
          <p><span style={{ color: 'orange' }}>Previous Analysis (Jul 5):</span> Hurricane landfall over TX evening of July 7th.</p>
          <p>Austin can receive major rains the evening of July 7th through July 8th. Anticipated average rainfall is 5 inches over 5 hours (we will continue to update the).</p>
          <p>By midnight on July 8th, the system is expected to be over Central Texas, and the region will continue to receive rainfall for the following 12 hours.</p>
          <a href="https://berylforecast.streamlit.app/" target="_blank" rel="noopener noreferrer">Link to Web application</a>
        </div>
        <div className="footer-content">
          <p>Experimental Forecasts from UT Austin - Jackson School of Geosciences/ TExUS Lab</p>
          <p>This is an experimental/research output as part of model runs for projects through Department of Energy, National Science Foundation, NASA, and a MoA with NOAA - Hurricane Research Division. The model runs are on TACC compute resources at UT. This experimental forecast is not for public advisory or use, or any decision making and is only updated here for the lab and project teams to test the models. No liability is assumed. The official forecasts are available from NOAA/NWS <a href="https://www.weather.gov/ewx/" target="_blank" rel="noopener noreferrer">https://www.weather.gov/ewx/</a> and <a href="https://www.nhc.noaa.gov/" target="_blank" rel="noopener noreferrer">https://www.nhc.noaa.gov/</a>.</p>
        </div>
      </div>
{/* 
      <div className="image-container-1">
        <img src="images/image10.png" alt="New Image" />
      </div>

      <div className="image-container-1">
        <img src="images/hurricane_img.png" alt="New Image" />
      </div> */}

      <div className="cards-container">
        {/* <div className="card">
          <div className="card-image">
            <img src="images/image10.gif" alt="Card 1" />
          </div>
          <div className="card-content1">
            <h2>Experimental HWRFx @UT Runs. Model does not have ocean coupling or data assimilation in these runs.</h2>
          </div>
        </div> */}

        <div className="card flipped">
          <div className="card-content1">
            <h2>Graphcast-Operational real time runs (the model is based on AI/ML approaches as run at UT)</h2>
          </div>
          <div className="card-image">
            <img src="images/graphcast_cuml_precp.jpeg" alt="Card 2" />
          </div>
        </div>
        <div className="card flipped">
        <div className="card">
          <div className="card-image">
          <img src="images/tropicalstormdebby.jpeg" alt="Card 4" />
          </div>
          <div className="card-content1">
          <h2>National Hurricane Center Cone of prediction for Hurricane Debby <br/> Last updated: 07/05/2024 1:00 PM CDT</h2>
          <p>https://www.nhc.noaa.gov/</p>
            </div>
        </div>
        </div>
        {/* <div className="card flipped">
          <div className="card-content1">
            <h2>National Hurricane Center Cone of prediction for Hurricane Debby <br/> Last updated: 07/05/2024 1:00 PM CDT</h2>
            <p>https://www.nhc.noaa.gov/</p>
          </div>
          <div className="card-image">
            <img src="images/tropicalstormdebby.jpeg" alt="Card 4" />
          </div>
        </div> */}
        {/* <div className="card">
          <div className="card-image">
            <img src="images/image2.gif" alt="Card 5" />
          </div>
          <div className="card-content1">
            <h2>Cumulative Quantitative Precipitation Estimates (QPEs) from different models</h2>
          </div>
        </div> */}
        {/* <div className="card flipped">
          <div className="card-content1">
            <h2>Quantitative Precipitation Estimates (QPEs) from different models</h2>
          </div>
          <div className="card-image">
            <img src="images/image9.gif" alt="Card 6" />
          </div>
        </div>

        <div className="card">
          <div className="card-image">
            <img src="images/image6.jpg" alt="Card 5" />
          </div>
          <div className="card-content1">
            <h2>Mean Rainfall over Austin from Experimental HWRFx @UT</h2>
          </div>
        </div> */}

      </div>
      <div className="bottom-text">
        <p>The model outputs are from UT Austin Jackson School of Geosciences/TExUS Lab. These are based on AI-ML Google Graphcast Operational Runs at UT and hOLAI model from NOAA as part of the UT Austin - NOAA Research Agreement.</p>
      </div>
    </div>
  );
}

export default HurricaneDebby;
