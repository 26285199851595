import React from 'react';
import { Link } from 'react-router-dom';
import './News.css'; // You should create a new CSS file for news styling.
import NewsInfo from './NewsInfo'; // Use a default import for NewsInfo

function News() {
  const reversedNewsInfo = [...NewsInfo].reverse(); // Create a copy of NewsInfo in reverse order

  const renderNews = () => {
    return reversedNewsInfo.map((article, index) => {
      // Toggle imageOnLeft based on even/odd index
      const imageOnLeft = index % 2 === 0;

      return (
        
        <section className="news-article" key={index}>
          <div className={`article-content ${imageOnLeft ? 'image-on-left' : 'image-on-right'}`}>
            
            <div className="image-container">
              <img src={article.image} alt={`Article ${index}`} />
            </div>

            <div className="article-text">
              <p className= "date">{article.date}</p>
              <Link to={article.link} className="title-text">
                {article.title}
              </Link>
              <p className= "subtext">{article.content}</p>
            </div>
          </div>
        </section>
      );
    });
  };

   return (
    <div>
      <div className="header">

        <h1>News </h1>
      </div>
      <div className="news-container">{renderNews()}</div>
    </div>
  );
}

export default News;