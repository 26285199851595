import React from 'react';
import { Link } from 'react-router-dom';

function NewsCard({ month, day, content, linkTo }) {
  return (
    <div className="news-card">
      <div className="news-date">
        <div className ="news-month">{month} </div>
        <div className ="news-day">{day} </div>
      </div>
      <div className="news-content">
         <Link to={'/News'} className = "links">{content}</Link>

      </div>
    </div>
  );
}

export default NewsCard;