import React from 'react';
import styles from './GeoHealth.module.css'

const CourseModules = () => (
  <div className={styles.courseModules}>
    <h2>Course Modules</h2>
    <table className={styles.modulesTable}>
      <thead>
        <tr>
          <th>Week</th>
          <th>Date</th>
          <th>Module</th>
          <th>Topics</th>
          <th>HW</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>16-Jan</td>
          <td>Intro</td>
          <td>Introduction and Outline of the Course</td>
          <td></td>
        </tr>
        <tr>
          <td>1</td>
          <td>18-Jan</td>
          <td>Intro</td>
          <td>Intro to Geohealth and it's Significance/ thoughts on the Vikings                                                Readings Due :                                                                                                                 1. https://www.agu.org/Share-and Advocate/Share/Policymakers/Position-Statements/Fact-sheet-Geohealth                                                                                                                         2. https://www.cdc.gov/climateandhealth/effects/default.htm             3. https://www.smithsonianmag.com/history/why-greenland-vikings-vanished-180962119/</td>
          <td>HW#1 Due</td>
        </tr>
        <tr>
          <td>2</td>
          <td>23-Jan</td>
          <td>Module 1: Heat</td>
          <td>What is Extreme Urban Heat?                                                                      Readings:                                                                                                          1. Ulpiani, G. (2021). On the linkage between urban heat island and urban pollution island: Three-decade literature review towards a conceptual framework. Science of the total environment, 751, 141727. 2. Saverino, K. C., Routman, E., Lookingbill, T. R., Eanes, A. M., Hoffman, J. S., & Bao, R. (2021). Thermal inequity in Richmond, VA: the effect of an unjust evolution of the urban landscape on urban heat islands. Sustainability, 13(3), 1511.                                                                                                                        3. https://education.nationalgeographic.org/resource/urban-heat-island/</td>
          <td></td>
        </tr>
        <tr>
          <td>2</td>
          <td>25-Jan</td>
          <td>Module 1: Heat</td>
          <td>Discussion of Urban Heat, it's inequalities, and impact on People Readings Due-                                                                                                                 1. Luber, G., & McGeehin, M. (2008). Climate change and extreme heat events. American journal of preventive medicine, 35(5), 429-435.         2. Hughes, L., Hanna, E., & Fenwick, J. (2016). The silent killer: Climate change and the health impacts of extreme heat.                                            3. https://www.theguardian.com/us-news/2023/nov/11/phoenix-heat-deaths-record-climate-crisis</td>
          <td></td>
        </tr>
        <tr>
          <td>3</td>
          <td>30-Jan</td>
          <td>Module 1: Heat</td>
          <td>Heat and Health (NIHHIS) and Talk with Hunter Jones Please examine Heat.gov in preparation</td>
          <td>Heat and Health (NIHHIS) and Talk with Hunter Jones Please examine Heat.gov in preparation</td>
        </tr>
        <tr>
          <td>3</td>
          <td>1-Feb</td>
          <td>Module 1: Heat</td>
          <td>Student Led Discussion #1</td>
          <td>HW #2 Due</td>
        </tr>
        <tr>
          <td>4</td>
          <td>6-Feb</td>
          <td>Module 2: Water</td>
          <td>Water and Diseases- CCAE Speaker                                                        Readings Due:                                                                                                                 1. Cissé, G. (2019). Food-borne and water-borne diseases under climate change in low-and middle-income countries: Further efforts needed for reducing environmental health exposure risks. Acta tropica, 194, 181-188.</td>
          <td></td>
        </tr>
        <tr>
          <td>4</td>
          <td>8-Feb</td>
          <td>Module 2: Water</td>
          <td>Flint Michigan and Trafigura A case of environmental injustice Readings Due:                                                                                                                 1. Butler, L. J., Scammell, M. K., & Benson, E. B. (2016). The Flint, Michigan, water crisis: A case study in regulatory failure and environmental injustice. Environmental Justice, 9(4), 93-97.                    2. White, R. (2008). Toxic cities: Globalizing the problem of waste. Social Justice, 35(3 (113), 107-119.</td>
          <td></td>
        </tr>
        <tr>
          <td>5</td>
          <td>13-Feb</td>
          <td>Module 2: Water</td>
          <td>Flooding                                                                                                                Readings Due:                                                                                                                        1. Mirza, M. M. Q. (2011). Climate change, flooding in South Asia and implications. Regional environmental change, 11(Suppl 1), 95-107.         2. Miller, J. D., & Hutchins, M. (2017). The impacts of urbanisation and climate change on urban flooding and urban water quality: A review of the evidence concerning the United Kingdom. Journal of Hydrology: Regional Studies, 12, 345-362.                                                                                  3. https://www.scientificamerican.com/article/first-ever-flood-forecasting-maps-show-houses-and-roads-at-risk/</td>
          <td></td>
        </tr>
        <tr>
          <td>5</td>
          <td>15-Feb</td>
          <td>Module 2: Water</td>
          <td>Student Led Discussion #2</td>
          <td>HW #3 Due</td>
        </tr>
        <tr>
          <td>6</td>
          <td>20-Feb</td>
          <td>Module 3: Air Quality</td>
          <td>Smog                                                                                                                      Readigns Due:                                                                                                                 1. Polivka, B. J. (2018). The great London smog of 1952. AJN The American Journal of Nursing, 118(4), 57-61.                                                       2. Zhu, W., Yao, N., Guo, Q., & Wang, F. (2020). Public risk perception and willingness to mitigate climate change: city smog as an example. Environmental geochemistry and health, 42, 881-893.</td>
          <td></td>
        </tr>
        <tr>
          <td>6</td>
          <td>22-Feb</td>
          <td>Module 3: Air Quality</td>
          <td>Wildfires, Dust, allergens and cascading impacts                                 Readings Due:                                                                                                                 1. Katelaris, C. H., & Beggs, P. J. (2018). Climate change: allergens and allergic diseases. Internal medicine journal, 48(2), 129-134.</td>
          <td></td>
        </tr>
        <tr>
          <td>7</td>
          <td>27-Feb</td>
          <td>Module 3: Air Quality</td>
          <td>Student Led Discussion #3</td>
          <td>HW #4 Due and topics for term papers</td>
        </tr>
        <tr>
          <td>7</td>
          <td>29-Feb</td>
          <td>Module 4: Mental Health</td>
          <td>Ashley Hawes and Austin Public health</td>
          <td></td>
        </tr>
        <tr>
          <td>8</td>
          <td>5-Mar</td>
          <td>Module 4: Mental Health</td>
          <td>Climate Nihilism                                                                                                  Readings Due:                                                                                                                 1. https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3918955                                                                                                     2. https://www.forbes.com/sites/davidcarlin/2021/04/21/fighting-back-against-the-3-ds-of-climate-inaction/?sh=750f7dc618ae                       3. https://iep.utm.edu/nihilism/</td>
          <td></td>
        </tr>
        <tr>
          <td>8</td>
          <td>7-Mar</td>
          <td>Module 4: Mental Health</td>
          <td>Student Led Discussion #4</td>
          <td>HW #5 Due</td>
        </tr>
        <tr>
          <td></td>
          <td>March 11-16 Spring Break (No Class)</td>
          <td>March 11-16 Spring Break (No Class)</td>
          <td></td>
          <td>Preliminary Bibliography Due</td>
        </tr>
        <tr>
          <td>9</td>
          <td>19-Mar</td>
          <td>Module 5 : Infection and Vector Borne Diseases</td>
          <td>Spread of vector borne diseases                                                              Readings Due:                                                                                                                 1. https://www.nature.com/articles/d41586-023-03476-7 2. Githeko, A. K., Lindsay, S. W., Confalonieri, U. E., & Patz, J. A. (2000).                            2. Climate change and vector-borne diseases: a regional analysis. Bulletin of the world health organization, 78(9), 1136-1147.                      3. https://www.msn.com/en-in/news/techandscience/real-world-origin-of-the-cordyceps-fungus-zombie-from-the-last-of-us/ar-AA16ufZv</td>
          <td></td>
        </tr>
        <tr>
          <td>9</td>
          <td>21-Mar</td>
          <td>Module 5 : Infection and Vector Borne Diseases</td>
          <td>COVID-19 and Pandemics                                                                             Readings Due:                                                                                                                 1. Norouzi, N., de Rubens, G. Z., Choupanpiesheh, S., & Enevoldsen, P. (2020). When pandemics impact economies and climate change: Exploring the impacts of COVID-19 on oil and electricity demand in China. Energy research & social science, 68, 101654.                                      2. https://www.nature.com/articles/s41591-021-01303-y                           3. https://www.science.org/doi/10.1126/science.adk4500</td>
          <td></td>
        </tr>
        <tr>
          <td>10</td>
          <td>26-Mar</td>
          <td>Module 5 : Infection and Vector Borne Diseases</td>
          <td>Spread of infectious diseases and exposure sensing                       Readings Due:                                                                                                                 1. Munzert, S., Selb, P., Gohdes, A., Stoetzer, L. F., & Lowe, W. (2021). Tracking and promoting the usage of a COVID-19 contact tracing app. Nature human behaviour, 5(2), 247-255.                                                             2. Klein, A. Z., Magge, A., O'Connor, K., Flores Amaro, J. I., Weissenbacher, D., & Gonzalez Hernandez, G. (2021). Toward using Twitter for tracking COVID-19: a natural language processing pipeline and exploratory data set. Journal of medical Internet research, 23(1), e25314.                                                                                                                               3. Garrett, P. M., White, J. P., Lewandowsky, S., Kashima, Y., Perfors, A., Little, D. R., ... & Dennis, S. (2021). The acceptability and uptake of smartphone tracking for COVID-19 in Australia. Plos one, 16(1), e0244827.</td>
          <td></td>
        </tr>
        <tr>
          <td>10</td>
          <td>28-Mar</td>
          <td>Module 5 : Infection and Vector Borne Diseases</td>
          <td>Student Led Discussion #5</td>
          <td>HW #6 Due and Draft of Papers Due</td>
        </tr>
        <tr>
          <td>11</td>
          <td>2-Apr</td>
          <td>Module 6: Food Security</td>
          <td>Climate Change and Food Insecurity                                                         Readings Due:                                                                                                                 1. Bohle, H. G., Downing, T. E., & Watts, M. J. (1994). Climate change and social vulnerability: toward a sociology and geography of food insecurity. Global environmental change, 4(1), 37-48.                                   2. https://www.worldbank.org/en/news/feature/2022/10/17/what-you-need-to-know-about-food-security-and-climate-change</td>
          <td></td>
        </tr>
        <tr>
          <td>11</td>
          <td>4-Apr</td>
          <td>Module 6: Food Security</td>
          <td>Food Insecurity and Health                                                                             Readings Due:                                                                                                                 1. Gundersen, C., & Ziliak, J. P. (2015). Food insecurity and health outcomes. Health affairs, 34(11), 1830-1839 2. Ivers, L. C., & Cullen, K. A. (2011).                                                                                                                           2. Food insecurity: special considerations for women. The American journal of clinical nutrition, 94(6), 1740S-1744S.</td>
          <td>Term Papers Due</td>
        </tr>
        <tr>
          <td>12</td>
          <td>9-Apr</td>
          <td>Module 6: Food Security</td>
          <td>Measuring Food Deserts and Food insecurity                                         Readings Due:                                                                                                                  1. Barrett, C. B. (2010). Measuring food insecurity. Science, 327(5967), 825-828.                                                                                                                             2. Carlson, S. J., Andrews, M. S., & Bickel, G. W. (1999). Measuring food insecurity and hunger in the United States: development of a national benchmark measure and prevalence estimates. The Journal of nutrition, 129(2), 510S-516S.</td>
          <td></td>
        </tr>
        <tr>
          <td>12</td>
          <td>11-Apr</td>
          <td>Module 6: Food Security</td>
          <td>Student Led Discussion #6</td>
          <td></td>
        </tr>
        <tr>
          <td>13</td>
          <td>16-Apr</td>
          <td>Module 7: Migration and Policy Solutions</td>
          <td>Human Migration due to Climate Change                                                 Readings Due:                                                                                                                 1. Black, R., Adger, W. N., Arnell, N. W., Dercon, S., Geddes, A., & Thomas, D. (2011). The effect of environmental change on human migration. Global environmental change, 21, S3-S11.                                      2. https://www.cfr.org/in-brief/climate-change-fueling-migration-do-climate-migrants-have-legal-protections                                                           3. Rigaud, K. K., De Sherbinin, A., Jones, B., Bergmann, J., Clement, V., Ober, K., ... & Midgley, A. (2018). Groundswell: Preparing for internal climate migration.</td>
          <td></td>
        </tr>
        <tr>
          <td>13</td>
          <td>18-Apr</td>
          <td>Module 7: Migration and Policy Solutions</td>
          <td>Case Studies of Forced Migration and or Environmental Racism (Love Island, Warren NC) Readings Due:                                                                         1. https://www.nytimes.com/2013/11/25/booming/love-canal-and-its-mixed-legacy.html                                                                                                          2. https://chej.org/about-us/story/love-canal                                                 3. https://www.washingtonpost.com/climate-environment/interactive/2021/environmental-justice-race/</td>
          <td></td>
        </tr>
        <tr>
          <td>14</td>
          <td>23-Apr</td>
          <td>Presentations</td>
          <td>Presentations</td>
          <td></td>
        </tr>
        <tr>
          <td>14</td>
          <td>25-Apr</td>
          <td>Presentations</td>
          <td>Presentations</td>
          <td></td>
        </tr>
        <tr>
          <td>15</td>
          <td>29-Apr</td>
          <td>Wrap Up</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default CourseModules;