import React from 'react';
import "./CoLab.css";
import Timeline from '../Timeline';
import { Helmet } from 'react-helmet';




const timelineEvents = [
  { year: "2023", description: "Launch CoLab, Hire Staff, Governance Framework" },
  { year: "2024 Output 1", description: "Launch Climate Assessment Report" },
  { year: "2024 Output 2", description: "At least 1 decision workflow, Launch Climate Internship" },
  { year: "2024 Output 3", description: "At least 1 data product, modeling study, external funding & policy brief" },
  // Add more events here INCREASE OR DECREASE PADDING WHEN ADDING TO TIMELINE
];

const CoLab = () => {

    

  return (
    <div className="colab-main">
        <Helmet>
        <title>UT City Co-Lab</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://texuslab.org/colab" />
        <meta property="og:title" content="UT City Co-Lab" />
        <meta property="og:description" content="Connecting climate data to Community + City decision making" />
        <meta property="og:image" content="https://texuslab.org/images/seal.svg" />
        

      </Helmet>

{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* Header  */}



        <div className="colab-header">
            <img src={`${process.env.PUBLIC_URL}/images/downtown-austin-skyline.jpg`} className="top-image"/> 
            <div className="overlay">
                <h1>UT-City Climate CoLab</h1>
                <img src={`${process.env.PUBLIC_URL}/images/colab_logo.png`} className="colab-logo"/>

            </div>
            <img src={`${process.env.PUBLIC_URL}/images/seal.png`} className="coa-logo"/>

             <img src={`${process.env.PUBLIC_URL}/images/seal.svg`} className="ut-seal"/>
             <div className="scroll-down-box">
                <a href="#target-section">
            <img src={`${process.env.PUBLIC_URL}/images/scroll-down.svg`} className="scroll-down"/>
            </a>
            
            </div>
            <div className="button-grid">
        
            <a href={`${process.env.PUBLIC_URL}/pdf/colab_overview.pdf`} className="pdf-button">
            <div className="pdf-button-content">Colab Overview</div>
        </a>
        <a href={`${process.env.PUBLIC_URL}/pdf/Climate_report.pdf`} className="pdf-button">
            <div className="pdf-button-content">Austin Future Climate Report 2024</div>
        </a>
        <a href={`${process.env.PUBLIC_URL}/pdf/seasonal_outlook.pdf`} className="pdf-button">
            <div className="pdf-button-content">Austin Summer Outlook</div>
        </a>
        <a href={`${process.env.PUBLIC_URL}/pdf/heat_playbook.pdf`} className="pdf-button">
            <div className="pdf-button-content">Heat Resilience Playbook</div>
        </a>
       
    </div>

            

             
        </div>

{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* Problems  */}

        <div className="Problems" id="target-section">
            <h1>PROBLEMS TO SOLVE</h1>
            <div className="circle-section"> 
            <div className="circle">Fills a void of directly supporting City climate data needs.</div>
            <div className="circle">City data needs vary across departments. Data need to be connected to city department decision making.</div>
            <div className="circle">Specific data and models are often confusing for community and staff. The goal is to make research accessible to all.</div>
            <div className="circle">UT / City climate research projects need stakeholder partnerships to be successful.</div>
            </div>
                
         </div>

{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* Urgency  */}

         <div className="urgency">
            <img src={`${process.env.PUBLIC_URL}/images/doggetposter.jpg`} className="group-pic"/>
            <div className="urgency-text">
                <h1 className="urgency-title">URGENCY</h1>
                <p className="urgency-content">Increase in the climate extremes; city needs to prepare for eventualities.<br /> <br /> Disadvantaged communities greatly challenged in “bouncing back”.<br /> <br />Infrastructure and response plans underway.<br /> <br />Students interested in place-based research.<br /> <br />  Federal grant opportunities are growing.</p>

            </div>
        </div>
        

{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* FOCUS  */}

        <div className="focus">
            <div className="focus-box">
                <h1 className="focus-title">UT FOCUS</h1>

                <ul className="focus-content">
                    <li>Products and research staff position</li>
                    <li>Build on Planet Texas 2050 and AI for Good Systems Initiatives at UT</li>
                    <li>Coordinate access to expertise across UT, and undertake research projects</li>
                    <li>Develop data, product tools, and policy briefs/ assessments</li>
                    
                </ul> 

            </div>

            <div className="focus-box">
                <h1 className="focus-title">CITY FOCUS</h1>
                <ul className="focus-content">
                    <li>Project Manager embedded with City</li>
                    <li>Coordinate Access to City departmental staff and decisions</li>
                    <li>Provide feedback and implement data and tools</li>
                    <li>Co-development and Policy as well as Community Interface</li>
                </ul> 

            </div>
        </div>
{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* Project Cards  */}

        <div className="colab-ongoing" style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/downtown-austin-skyline.jpg)`,
        backgroundPosition: '0px -400px' // Replace Xpx and Ypx with your desired values
    }}>

            <div className="colab-ongoing-card">         {/* Copy  */}
            <div className="colab-section">
                <img src={`${process.env.PUBLIC_URL}/images/section1.png`} alt="Description for first image" />
                <h1 className="ongoing-title">CLIMATE ASSESSMENTS</h1>   
                <p>Climate trends, patterns, changes, event summaries <br /> <br /> Case studies for high impact events. <br /> <br /> Future Projections </p>
                </div>

            </div>

            <div className="colab-ongoing-card">
                <div className="colab-section">
                    <img src={`${process.env.PUBLIC_URL}/images/section2.png`} alt="Description for second image" />
                    <h1 className="ongoing-title">DECISION CALENDARS</h1>   
                    <p>Work with City operations <br/> <br /> Document What climate information is needed, Who needs it, When, Why? <br /> <br /> How accurate, and How will it be used?</p>
                </div>
            </div>

            <div className="colab-ongoing-card">
                <div className="colab-section">
                    <img src={`${process.env.PUBLIC_URL}/images/section3.png`} alt="Description for third image" />
                    <h1 className="ongoing-title">POLICY BRIEFS</h1>   
                    <p>Map networks for outreach, programs, and community organizations links <br /> <br /> Connect climate modeling data products to social and policy science, social vulnerability </p>
                </div>
            </div>


            <div className="colab-ongoing-card">
                <div className="colab-section">
                    <img src={`${process.env.PUBLIC_URL}/images/section4.png`} alt="Description for fourth image" />
                    <h1 className="ongoing-title">CITY WIDE DATA PRODUCTS</h1>   
                    <p>High resolution data based on different department needs <br/> <br/> Local-scale climate products <br/> <br/> Combine Data + Lived Experience</p>
                </div>
            </div>



            <div className="colab-ongoing-card">
                <div className="colab-section">
                   <img src={`${process.env.PUBLIC_URL}/images/section5.png`} alt="Description for fifth image" />
                    <h1 className="ongoing-title">SYNTHESIS STUDIES</h1>   
                    <p>Cool Pavement <br/> <br/> Fire, flood, hotspots <br/> <br/> If-then assessments for future city growth and climate <br/> <br/> Classroom and public education</p>
                </div>
            </div>


        </div>
{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* CoLab Structure  */}
        <div className="colab-structure">
            <h1 className="structure-title">CO-LAB STRUCTURE</h1>

            <div className="colab-structure-section">
                <div className="colab-structure-section-1">
                <img src={`${process.env.PUBLIC_URL}/images/Structure.png`} className="colab-structure-section-1-img"/>
                </div>

                <div className="colab-structure-section-2">
                    <div className="section-2-content">
                        <h1 className="headshots-title">City Leadership</h1>
                        <div className="headshot-row top-row">
                            <div className="headshot">
                               <img src={`${process.env.PUBLIC_URL}/images/headshot1.jpg`} alt="Headshot 1"/>
                                <p className="headshot-text">Marc Coudert</p>
                            </div>
                            
                            <div className="headshot">
                                <img src={`${process.env.PUBLIC_URL}/images/headshot5.jpg`} alt="Headshot 3"/>
                                <p className="headshot-text">Zach Baumer</p>
                            </div>
                        </div>
                    </div>

                    <div className="section-2-content">
                        <h1 className="headshots-title">Project Manager</h1>
                        <div className="headshot-row top-row">
                            <div className="headshot">
                               <img src={`${process.env.PUBLIC_URL}/images/alexia.jpeg`} alt="Headshot 1"/>
                                <p className="headshot-text">Alexia Leclercq</p>
                            </div>
                            

                        </div>
                    </div>

                    
                    <div className="section-2-content">
                        <h1 className="headshots-title">UT Leadership</h1>
                        <div className="headshot-row bottom-row">
                            <div className="headshot">
                                <img src={`${process.env.PUBLIC_URL}/images/dev.jpg`} alt="Headshot 4"/>
                                <p className="headshot-text">Dev Niyogi</p>
                            </div>
                            <div className="headshot">
                                <img src={`${process.env.PUBLIC_URL}/images/headshot2.jpg`} alt="Headshot 5"/>
                                <p className="headshot-text">Junfeng Jiao</p>
                            </div>
                            <div className="headshot">
                                <img src={`${process.env.PUBLIC_URL}/images/headshot3.jpg`} alt="Headshot 6"/>
                                <p className="headshot-text">Patrick Bixler</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>





{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* ------------------------------------------------------------------------------------------*/}
{/* Timeline  */}

        <div className="colab-progress">
            <div className="next-steps">
                <h1 className="colab-progress-title">NEXT STEPS</h1>
                <ul className="next-steps-list">
                    <li>Make recently downscaled data accessible to the community.</li>
                    <li>Develop inventory of actions taking place – resource database activities in climate mitigation & adaptation</li>
                    <li>Climate Decision Calendar – working with AFD, and other departments</li>
                    <li>Create a climate resilience, heat mapping/ mitigation tool kit</li>
                    <li>Finalize Governance and Reporting Structure for Co-Lab</li>
                </ul>
            </div>

            

        </div>

        

    </div>
  )
}

  export default CoLab;


  
