import React from "react";
import "./Join.css";

function Join() {
  return (
    <div className="join-container">
      <div className="img-container">
      
      <img
        src="https://texuslab.org/images/Umbrella.jpeg"
        alt="Promising Students"
        className="header-image"
      />
      </div>
      <div className="content">
        <h1 className="join-header">Join Us!</h1>
        <p className="join-subheader">
          We are always looking for promising students! <br /><br/>
          Undergraduate / Graduate / Post-Doc students are welcome. If
          interested, please send an email to <a href="mailto:happy1@utexas.edu">happy1@utexas.edu</a>
        </p >

        <p className="info">
          
          <br />To join us, please include the following in your email:<br />
          Year of study and degree program<br />
          CV/ Resume<br />
          What you are looking for (Research, Potential Grad Student, For credit undergrad topic etc.)
          
        </p>
      </div>
    </div>
  );
}

export default Join;
