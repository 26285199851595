import React, { useState }from 'react';
import './ParisRDP.css'; // Create a new CSS file for Paris styling.
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


function ParisOlympics03Aug() {
  const [value, onChange] = useState(new Date());
  const animationresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1
    }
  };

  const onChangeDate = (date) => {
    const checkDate = new Date(date);
    const stringDate = checkDate.toString();

    if(stringDate.includes("Jul 26") == true){
      //archiveurl
      window.open('/ParisOlympics26Jul', '_blank');
    }
    if(stringDate.includes("Jul 29") == true){
      //archiveurl
      window.open('/ParisOlympics29Jul', '_blank');
    }
    if(stringDate.includes("Jul 30") == true){
      //archiveurl
      window.open('/ParisOlympics30Jul', '_blank');
    }
    if(stringDate.includes("Jul 31") == true){
      //archiveurl
      window.open('/ParisOlympics31Jul', '_blank');
    }
    if(stringDate.includes("Aug 01") == true){
      //archiveurl
      window.open('/ParisOlympics01Aug', '_blank');
    }
    if(stringDate.includes("Aug 02") == true){
      //archiveurl
      window.open('/ParisOlympics02Aug', '_blank');
  }
    if(stringDate.includes("Aug 03") == true){
        //archiveurl
        window.open('/ParisOlympics03Aug', '_blank');
    }
    if(stringDate.includes("Aug 04") == true){
      //archiveurl
      window.open('/ParisOlympics04Aug', '_blank');
  }
  if(stringDate.includes("Aug 05") == true){
    //archiveurl
    window.open('/ParisOlympics05Aug', '_blank');
  }
  if(stringDate.includes("Aug 06") == true){
    //archiveurl
    window.open('/ParisOlympics06Aug', '_blank');
  }
  if(stringDate.includes("Aug 07") == true){
    //archiveurl
    window.open('/ParisOlympics07Aug', '_blank');
  }
  if(stringDate.includes("Aug 08") == true){
    //archiveurl
    window.open('/ParisOlympics08Aug', '_blank');
  }
  if(stringDate.includes("Aug 09") == true){
    //archiveurl
    window.open('/ParisOlympics09Aug', '_blank');
  }
  if(stringDate.includes("Aug 10") == true){
    //archiveurl
    window.open('/ParisOlympics10Aug', '_blank');
  }
  if(stringDate.includes("Aug 11") == true){
    //archiveurl
    window.open('/ParisOlympics', '_blank');
  }
  };

  return (
    <div className="main">
      <div className="parisheader">
        <img src="images/parisbackround.png" alt="Background" className="paris-background" />
        <div className="title-logo">
        <h1>Paris Olympics<br />RDP</h1>
          <img src="images/parislogo.png" alt="Paris olympic logo" />
        </div>
        <div className="heading-text">
          <h2>Paris 2024 Olympics Weather Forecast</h2><br/>
          <h2>UT Meteogan Downscaling in partnership with TACC as part of WMO RDP</h2>
          <a href="https://www.linkedin.com/posts/research-at-the-university-of-texas-at-austin_olympics-meteorological-paris2024-activity-7222594602212454401-mCF-?utm_source=share&utm_medium=member_android"> <button className="parisbutton">Read UT Research Announcement</button></a>
          <a href="https://wmo.int/media/news/paris-olympics-will-advance-research-weather-forecasting-and-urban-meteorology" target="_blank" rel="noopener noreferrer"> <button class="parisbutton">Read WMO Article</button></a>
        </div>
        <div class="calendargrid">
          <h1>Forecast Calendar</h1>
          <br/>
            <Calendar
                onChange={onChangeDate}
                value={value}
                minDate={new Date('July 26, 2024 23:15:30')}               
                 />
        </div>
        <div className="footer-text">
          <p><a href="https://wmo.int/media/news/paris-olympics-will-advance-research-weather-forecasting-and-urban-meteorology" target="_blank" rel="noopener noreferrer">Image Source: WMO News</a></p>
        </div>
        <div className="footer-content">
          <p>Experimental Forecasts from UT Austin - Jackson School of Geosciences/ TExUS Lab</p>
          <p>This is an experimental/research model study. The model runs are on TACC compute resources at UT. This experimental forecast is not for public advisory or use, or any decision making and is only updated here for the lab and project teams to test the models. No liability is assumed. The official forecasts are available from NOAA/NWS <a href="https://www.weather.gov/ewx/" target="_blank" rel="noopener noreferrer">https://www.weather.gov/ewx/</a> and <a href="https://www.nhc.noaa.gov/" target="_blank" rel="noopener noreferrer">https://www.nhc.noaa.gov/</a>.</p>
        </div>
      </div>
     
      <div class="graph-title">
        <h1>Observed Precipitation from Radar</h1>
        </div>
        <Carousel responsive={animationresponsive}>
      <div class="graph-box">
        <h2>1 Aug 2:15-4:15</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_1aug_2-4.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>1 Aug 6:20-8:20</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_1aug_6-8.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>2 Aug 14:00-16:00</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_2aug_14-16.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>2 Aug 18:20-20:20</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_2aug_18-20.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>2 Aug 22:30-00:30</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_2aug_22.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>2 Aug 1:55-3:50</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_2aug_1-3.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>3 Aug 7:10-9:10</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_3aug_7-9.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>3 Aug 14:30-14:30</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_3aug_14-16.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>4 Aug 3:45-5:45</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_4aug_3-6.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>5 Aug 23:15-1:15</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_6aug_23-1.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>6 Aug 23:45-1:45</h2>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_6aug_23_2.mov" />
    </video>
      </div>
      </Carousel>
     
      <div class="graph-title">
        <h1>Model Forecast starting 3 Aug 2024 00 UTC</h1>
        </div>
      <div class="graph-title">
      <h2>Precipitation Forecast</h2><br/>
         <p>Light precipitation of approximately 2 mm is expected in the northwest of Paris on the evening of August 3rd, followed by light showers in the southeast on the morning of August 4th. However, Paris is forecasted to remain mostly dry over the next 24 to 36 hours, with some drizzle that could be expected in the evening.</p>
      </div>
      
      <div className="graphs-container">
        <img src="/images/mean_surf_precp_3aug.png" alt="total surface 31 Jul" />
        <img src="/images/total_surf_precp_3aug_24hrs.png" alt="total surface 31 Jul" />
        <img src="/images/total_surf_precp_3aug_36hrs.png" alt="Mean surface 31Jul" />
        <img src="/images/total_surf_precp_3aug.gif" alt="total surface 31 Jul" />  
      </div>

      <div class="graph-title">
      <h2>Surface Temperature Forecast</h2><br/>
      <p>The maximum air temperature is expected to be around 24˚C today with the low being around 15˚C. Temperatures are anticipated to be milder due to the sea breeze from the west. 
      </p>
      </div>

      <div className="graphs-container">
        <img src="/images/mean_surf_air_temo_3aug.png" alt="Average Surface Temperature" />
        <img src="/images/avg_surf_air_temp_3aug_24hrs.png" alt="Average Surface Temperature" />
        <img src="/images/avg_surf_air_temp_3aug_36hrs.png" alt="Average Surface Temperature" />
        <img src="/images/surf_temp_3aug.gif" alt="Average Surface Temperature" /> 
      </div>

      <div class="graph-title">
      <h2>Wind Forecast</h2><br/>
      <p>A gentle breeze is expected today with maximum windspeeds around 15 km/hr coming from the SW. </p>
      </div>
      <div className="graphs-container">
      <img src="/images/mean_surf_winds_3aug.png" alt="Winds 2024-07-31" />
      <img src="/images/mean_winds_3aug_24hrs.png" alt="Mean Winds 31 July" />
      <img src="/images/mean_winds_3aug_36hrs.png" alt="Mean Winds 31 July" />
      <img src="/images/winds_3aug.gif" alt="Mean Winds 31 July" />
      </div>

      <div className="meteoganimage-container">
      <h1>UT-MeteoGAN details</h1>
        <p>Click below to view the MeteoGan Schematic</p>
        <a href="images/ut_meteogan_schematic.png"> <button className="parisbutton">MeteoGAN Schematic</button></a>
      </div>

      <div class="thermscale-box">
      <h1>Demo: Universal Thermal Climate Index for Paris</h1>
      <p> <bold>6 August 2024, 11 AM</bold></p>
      <br/>
      <iframe src="Paris_thermscale.html" width="100%" height="720"></iframe>
      </div>

      <div class="thermscale-box">
        <h1>Demo: UTCI Along The Olympics Marathon Path</h1><br/>
      <iframe width="100%" height="720" frameborder="0" scrolling="no" allowfullscreen src="https://ut-austin.maps.arcgis.com/apps/instant/3dviewer/index.html?appid=0624bfad92da47ffaa3e74b5689aacbc"></iframe>
      </div>
    <footer>
        <p>&copy; 2024 University of Texas at Austin, Jackson School of Geosciences. All rights reserved.</p>
    </footer>
    </div>
  );
}

export default ParisOlympics03Aug;
