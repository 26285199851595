import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

function Navbar({ navLinks }) {
  const [click, setClick] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [isOpaque, setIsOpaque] = useState(false);


   const location = useLocation();
  const hiddenRoutes = ['/geohealth', '/colab','/colab/','/audt','/hurricane','/hurricanedebby','/ParisOlympic','/ParisOlympics','/ParisOlympics26Jul','/ParisOlympics29Jul','/ParisOlympics30Jul','/ParisOlympics31Jul','/ParisOlympics01Aug','/ParisOlympics02Aug' ,'/ParisOlympics03Aug', '/ParisOlympics04Aug','/ParisOlympics05Aug','/ParisOlympics06Aug','/ParisOlympics07Aug','/ParisOlympics08Aug', '/ParisOlympics09Aug','/ParisOlympics10Aug'];

  useEffect(() => {
    console.log('Current path:', location.pathname); // Debug current path
    if (hiddenRoutes.includes(location.pathname)) {
      setHidden(true); // Hide the Navbar
    } else {
      setHidden(false); // Show the Navbar
    }
  }, [location]);
  
    const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  

  useEffect(() => {
    
    let prevScrollPos = window.pageYOffset;
    let isMouseAtTop = true;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (prevScrollPos < (currentScrollPos ) ) {
        // Scrolling down
        setHidden(true);
      } else if (currentScrollPos === 0) {
        // At the top of the screen
        setHidden(false);
        setIsOpaque(false);
      } else {
        setHidden(false);
        setIsOpaque(true);
      }


      prevScrollPos = currentScrollPos;
    };



    

    const handleMouseMove = () => {
      if (window.scrollY === 0) {
        // Mouse at the top of the screen
        isMouseAtTop = true;
        setHidden(false);
      } else if (isMouseAtTop) {
        isMouseAtTop = false;
        setHidden(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

      if (hiddenRoutes.includes(location.pathname)) {
    return null; // Don't render the Navbar for these paths
  }

  return (
    
    
    <nav className={`navbar ${hidden ? 'hidden' : ''} ${isOpaque ? 'opaque' : ''}`}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src= {process.env.PUBLIC_URL + '/images/jsg_white_digital.png'}  width= "240px" alt="Logo" className="logo-image" />
        </Link>

        <div className="texus-header" onClick = {handleClick}>
          <h5> TE(x)US L&Delta;B</h5>
        </div>


        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        

        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          {navLinks.map((navLink, index) => (
            <li className="nav-item" key={index}>
              <Link
                to={navLink.path}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                {navLink.label}
              </Link>
              {navLink.sublinks.length > 0 && (
                <ul className="dropdown-content">
                  {navLink.sublinks.map((sublink, subIndex) => (
                    <li className="nav-item" key={subIndex}>
                      <Link
                        to={sublink.path}
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        {sublink.label}
                      </Link>

                      {/* Check for nested sublinks */}
                      {sublink.innerSublinks && sublink.innerSublinks.length > 0 && (
                        <ul className="inner-dropdown-content">
                          {sublink.innerSublinks.map((innerSublink, innerIndex) => (
                            <li className="nav-item" key={innerIndex}>
                              <Link
                                to={innerSublink.path}
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                {innerSublink.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}

                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
          <Link to="/" className="navbar-logo2" onClick={closeMobileMenu}>
          <img src= {process.env.PUBLIC_URL + '/images/newLogo.svg'}  width= "70px" alt="Logo" className="logo-image2" />
        </Link>

        <Link to="/" className="navbar-logo3" onClick={closeMobileMenu}>
          <h5>TExUS Lab</h5>
        </Link>
        
      </div>
    </nav>
  );
}

export default Navbar;