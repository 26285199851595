import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './VideoSlider.css'

const VideoSlider = ({ videos }) => {

 const [videoWidth, setVideoWidth] = useState(1920); // Default width
  const [videoHeight, setVideoHeight] = useState(1080); // Default height

  // Adjust video dimensions based on screen width
  useEffect(() => {
    const handleResize = () => {
      console.log("width" + window.innerWidth);
      if (window.innerWidth <= 768) {
        // For mobile screens
        setVideoWidth(640);
        setVideoHeight(380);
        
      } else if (window.innerWidth <= 1920){ 
        setVideoWidth(1920);
        setVideoHeight(1080);
      }
      
      else {
        // For larger screens
        setVideoWidth(3840);
        setVideoHeight(2160);
      }
    };

    // Initial dimensions on component mount
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Carousel infiniteLoop = {true} autoPlay interval={7000}>
      {videos.map((video, index) => (
        <div key={index}>
            <div className="video-wrapper">
          <video autoPlay muted loop playsInline onLoadedData={() => console.log('Video loaded')}
 onPlay={() => console.log('Video started playing')}>
            <source src={video.src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </div>
          <p className="video-title">{video.title}</p>
        <p className="video-subtitle">{video.subtitle}</p>
        <p className="text-three">{video.thirdtext}</p>
        {video.logo && <img className="ut-logo" src={video.logo} alt="University Logo" />}
        </div>
      ))}
    </Carousel>
  );
};

export default VideoSlider;