import React from 'react';
import './People.css';
import { principalInvestigators, postdoctoralResearchers, graduateStudents, Staff, Undergrads, Visitors, VisitingSpeakers, Scientists, PastGrad } from '../teamData';

const People = () => {
  const renderTeamMembers = (members) => {
    return members.map((member, index) => (
      <div className="team-member" key={index}>
        <img src={member.image} alt={`Member ${index}`} />
        <p>{member.name}</p>
        {member.title && <p2>{member.title}</p2>}
      </div>
    ));
  };
  const renderTeamMembersNames = (members) => {
    return members.map((member, index) => (
      <div className="team-member" key={index}>
        <p>{member.name}</p>
        {member.title && <p2>{member.title}</p2>}
      </div>
    ));
  };

  return (
    <div className="meet-the-team">
      <h1>Meet the Team</h1>

      <section className="team-section-boss">
        <h2>Principal Investigator (PI)</h2>
        <div className="team-members-boss">
          {renderTeamMembers(principalInvestigators)}
        </div>
      </section>

      <section className="team-section">
        <h2>Postdoctoral Researchers</h2>
        <div className="team-members">
          {renderTeamMembers(postdoctoralResearchers)}
        </div>
      </section>

      <section className="team-section">
        <h2>Staff</h2>
        <div className="team-members">
          {renderTeamMembers(Staff)}
        </div>
      </section>

      <section className="team-section">
        <h2>Research Assistant Professors/Scientists</h2>
        <div className="team-members">
          {renderTeamMembers(Scientists)}
        </div>
      </section>

      <section className="team-section">
        <h2>Graduate Students</h2>
        <div className="team-members">
          {renderTeamMembers(graduateStudents)}
        </div>
      </section>

      <section className="team-section">
        <h2>Visiting Researchers</h2>
        <div className="team-members">
          {renderTeamMembersNames(Visitors)}
        </div>
      </section>

      <section className="team-section">
        <h2>Distinguished Visitors/ Seminar Speakers</h2>
        <div className="team-members">
          {renderTeamMembersNames(VisitingSpeakers)}
        </div>
      </section>

       <section className="team-section">
        <h2>Undergraduate Students</h2>
        <div className="team-members">
          {renderTeamMembersNames(Undergrads)}
        </div>
      </section>

       <section className="team-section">
        <h2>Past Graduate Students</h2>
        <div className="team-members">
          {renderTeamMembersNames(PastGrad)}
        </div>
      </section>

    </div>
  );
};

export default People;