import React from 'react';
import NewsCard from './NewsCard';
import NewsInfo from './pages/NewsInfo'; 

function parseDate(dateStr) {
  const monthDayRegex = /(\w+)\s(\d+)(?:th|st|nd|rd)/;
  const matches = dateStr.match(monthDayRegex);
  if (matches) {
    // Use slice(0, 3) to get the first three letters of the month
    return { month: matches[1].slice(0, 3), day: parseInt(matches[2]) };
  } else {
    return { month: '', day: '' };
  }
}

function NewsSection() {
  const reversedNewsData = [...NewsInfo].reverse(); 

  return (
    <div className="news-section">
      {reversedNewsData.map((newsItem, index) => {
        const { month, day } = parseDate(newsItem.date); // Use the parseDate function
        return (
          <React.Fragment key={index}>
            <NewsCard
              month={month}
              day={day}
              content={newsItem.title}

            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default NewsSection;