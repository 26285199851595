import React, { useState, useEffect } from 'react';
import './AUDT.css';

const AUDT = () => {
  const [isLinksVisible, setIsLinksVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Set links to be visible if the window width is greater than your breakpoint
      if (window.innerWidth >= 768) {
        setIsLinksVisible(true);
      }

      if (window.innerWidth <= 767) {
        setIsLinksVisible(false);
      }
      

    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleLinksVisibility = () => {
    console.log('Toggling links visibility. Current state is:', isLinksVisible);
    setIsLinksVisible(!isLinksVisible);
  };

  

  useEffect(() => {

    console.log('Links visibility state is now:', isLinksVisible);
  }, [isLinksVisible]);

  return (
    <div className="audt-container">
      <div className="quick-links">
        <button onClick={toggleLinksVisibility} className="toggle-button">
          {isLinksVisible ? 'Hide Links' : 'Show Links'}
        </button>
        {isLinksVisible && (
          <div className="links-container">
            <h3>Quick Links</h3>
            <a href="#goal">Goal</a>
            <a href="#scope">Scope</a>
            <a href="#participants">Involvement</a>
            <a href="#topics">Breakout Topics</a>
            <a href="#outcomes">Workshop Outcomes</a>
          </div>
        )}
      </div>




      <div className="header-section">
        <img className="header-img" src={process.env.PUBLIC_URL + "/images/grey_digital_twin_city_smart_cities_Adobe.jpg"}/>
        <div className="header-mask">
          <img className="org-logo" src={process.env.PUBLIC_URL + "/images/WCRP-logo-for-teal-background.png"} />
          <img className="org-logo NSF" src={process.env.PUBLIC_URL + "/images/NSF.svg.png"} />
          <img className="org-logo NSF" src={process.env.PUBLIC_URL + "/images/noaa_emblem_logo-2022.png"} />
        <h1 className='header-title'>Atmospheric and Urban Digital Twins Workshop</h1>
        </div>
      </div>

      <section className="audt-section" id="glance">
        <h2>Workshop at a Glance</h2>
        <p>Important Facts for the Workshop:</p>
        <ul>
          <li>The sign-up link to the workshop can be found <a href="https://forms.gle/t211PcpoTQ8GuAq79">here</a></li>
          <li>Workshop will be held <span style={{color:'#000', "font-weight":"bold"}}>Virtually</span> in <span style={{"font-weight":"bold"}}>late April</span></li>
         {/* <li>Workshop is designed to be 3-4 hours each day</li>*/}
          <li>The workshop will be divided into three main components: Definition/Foundation, Applications, and Education/Workforce Development</li>
          <li>With this workshop, we aim to bring together various perspectives, experts, and opinions to create a structure and framework to introduce atmospheric and urban digital twins to relevant groups.</li>
          <li>The workshop activities are supported through NSF AGS (2324744; Dr. N. Anderson and C. Lu)</li>
        </ul>
      </section>
      
      <section className="audt-section" id="goal">
        <h2>Goal</h2>
        <p>The primary goal of the workshop on Atmospheric and Urban Digital Twins (AUDT) is to foster development, understanding, and application of digital twin technologies in atmospheric and urban contexts. This aims to support weather and climate studies through advanced simulations, visualizations, and analyses, leveraging the growing capabilities in artificial intelligence (AI) and machine learning (ML), coupled with vast computational resources. The workshop intends to bridge the gap between academic research and practical applications, emphasizing the integration of physical and social sciences to enhance urban sustainability and smart city initiatives.
        </p>
      </section>

      <section className="audt-section" id="scope">
        <h2>Scope of Workshop</h2>
         <p>The scope of the workshop encompasses discussions related to definitions, applications, needs, opportunities, challenges and participation of academia in the development and widespread adoption of digital twins. It aims to:</p>

        <ul>
          <li>Develop clear definitions and requirements for atmospheric digital twins (ADTs) and urban digital twins (UDTs).</li>
          <li>Create exemplary demonstrators of AUDT to showcase their potential.</li>
          <li>Facilitate discussions on channeling various digital twin developments for enhanced weather and climate studies.</li>
        </ul>
        <p>The workshop will address the need for academic engagement in digital twin technology, focusing on areas not fully covered by current agency-driven efforts. It will explore the integration of ADTs and UDTs for improved urban planning and climate change adaptation, emphasizing the importance of interdisciplinary collaboration.</p>
       </section>


      <section className="audt-section" id="participants">
        <h2>Workshop Participants</h2>
        <p>The workshop will bring together a diverse group of participants, including:</p>
       <ul>
        <li>Academics and researchers in atmospheric sciences, urban planning, computer science, and related fields</li>
        <li>Practitioners from the weather and climate enterprise, urban planning, and infrastructure development sectors.</li>
        <li>Representatives from government agencies, non-profit organizations, and industry stakeholders interested in the application of AUDT for urban sustainability.</li>
        <li>Underrepresented groups in science and engineering, ensuring a diverse and inclusive discussion platform.</li>
       </ul>
      </section>


      <section className="audt-section" id="topics">
        <h2>Breakout Topics</h2>
        <p>The workshop will feature a range of breakout topics, including but not limited to:</p>
       <ul>
        <li>Definitions and standards for ADTs and UDTs.</li>
        <li>Integration of digital twins with AI and ML technologies for predictive modeling and simulations.</li>
        <li>Challenges and opportunities in representing urban components and dynamics within digital twins.</li>
        <li>Best practices and innovative approaches in developing and using AUDTs for climate change mitigation and urban planning.</li>
        <li>Case studies and demonstrator projects showcasing the application of AUDT in real-world scenarios.</li>
       </ul>
      </section>

      <section className="audt-section" id="outcomes">
        <h2>Workshop Outcomes</h2>
        <p>The anticipated outcomes of the workshop include:</p>
        <ul>
          <li>A white paper on the potential applications, challenges, and opportunities of combining atmospheric and urban digital twins, aimed at informing future research, policy-making, and urban planning efforts.</li>
          <li>Enhanced collaboration among the academic community, practitioners, and stakeholders in the development and application of AUDT technologies.</li>
          <li>Recommendations for integrating digital twin technologies into research and education, highlighting interdisciplinary opportunities and the need for a unified approach to urban sustainability and climate resilience.</li>
          <li>Establishment of a community of practice for ongoing dialogue, collaboration, and innovation in the field of atmospheric and urban digital twins.</li>
        </ul>
      </section>


             <section className="audt-section">
        <h2>Link to a Recent Presentaion at the AMS Annual Meeting</h2>
        <p></p>
        <iframe 
          src="https://ams104annual.ipostersessions.com/Default.aspx?s=b9-60-11-E1-04-0A-57-44-B8-94-1B-B9-70-5B-99-D7#" 
          width="100%" 
          height="700"

          style={{border: "none", borderRadius: "10px"}}
          title="UTwin - UT Austin Campus Digital Twin">
        </iframe>
      </section>




    </div>

    
  );

  
}

export default AUDT;
