import React from 'react';
import './PeopleDetails.css';

const PeopleDetails = ({ person, peopleProjects }) => {
  if (!person) {
    return <div>Person not found.</div>;
  }

  const formattedName = person.name.replace(/\s+/g, '_').toLowerCase();

  console.log('peopleProjects:', peopleProjects);


  return (
    <div className="main-page">
      <img src={person.image} alt={person.name} />
      
      <div className="associated-projects">
        <h2>{person.name}</h2>
        <h3>Associated Projects:</h3>
        <ul className="projects-content">
          {peopleProjects[formattedName] &&
            peopleProjects[formattedName].map((project) => (
              <li key={project.targetSection}>
                <a href={`/content/${project.targetSection}`}>{project.title}</a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default PeopleDetails;